import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "contractId",
    "agentName",
    "doctorEmail",
    "signInToken",
    "userEmail",
    "countryManagerEmail",
    "currentCoordinator",
    "doctorId",
  ];
  static values = { emailType: String };

  connect() {
    switch (this.emailTypeValue) {
      case "send_magic_link":
        this.toggleMagicLinkFields();
        break;
      case "contract_created":
        this.toggleContractCreated();
        break;
      case "contract_cancelled":
        this.toggleContractCancelled();
        break;
      case "supervisor_signed_contract_doctor":
        this.toggleSupervisorSignedContractDoctor();
        break;
      case "conference_attendance_confirmation":
        this.toggleConferenceAttendanceConfirmation();
        break;
      case "supervisor_signed_contract":
        this.toggleSupervisorSignedContract();
        break;
      case "supervisor_cancelled_contract":
        this.toggleSupervisorCancelledContract();
        break;
      case "coordinator_cancelled_contract":
        this.toggleCoordinatorCancelledContract();
        break;
      case "doctor_signed_contract":
        this.toggleDoctorSignedContract();
        break;
      case "doctor_updated_profile":
        this.toggleDoctorUpdatedProfile();
        break;
      default:
        console.error("Wrong email type");
    }
  }

  toggleMagicLinkFields() {
    this.contractIdTarget.querySelector(".form-control").disabled = true;
    this.agentNameTarget.querySelector(".form-control").disabled = true;
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
  }

  toggleContractCreated() {
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
  }
  toggleContractCancelled() {
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
  }
  toggleSupervisorSignedContractDoctor() {
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
  }
  toggleConferenceAttendanceConfirmation() {
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.agentNameTarget.querySelector(".form-control").disabled = true;
  }
  toggleSupervisorSignedContract() {
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
    this.doctorEmailTarget.querySelector(".form-control").disabled = true;
  }
  toggleSupervisorCancelledContract() {
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
    this.doctorEmailTarget.querySelector(".form-control").disabled = true;
  }
  toggleCoordinatorCancelledContract() {
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
    this.doctorEmailTarget.querySelector(".form-control").disabled = true;
    this.userEmailTarget.querySelector(".form-control").disabled = true;
    this.agentNameTarget.querySelector(".form-control").disabled = true;
  }
  toggleDoctorSignedContract() {
    this.doctorIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
    this.doctorEmailTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
  }
  toggleDoctorUpdatedProfile() {
    this.agentNameTarget.querySelector(".form-control").disabled = true;
    this.contractIdTarget.querySelector(".form-control").disabled = true;
    this.signInTokenTarget.querySelector(".form-control").disabled = true;
    this.currentCoordinatorTarget.querySelector(".form-control").disabled = true;
    this.countryManagerEmailTarget.querySelector(".form-control").disabled = true;
  }
}
