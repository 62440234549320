import { Controller } from "stimulus";
import { setVisibility } from "../helpers/stimulus";

export default class extends Controller {
  static targets = ["doctor", "personnel", "doctorRadio", "personnelRadio"];

  connect() {
    this.toggleDoctor();
    this.togglePersonnel();
  }

  toggleDoctor() {
    setVisibility(this.doctorTarget, this.doctorRadioTarget.checked);
    setVisibility(this.personnelTarget, this.personnelRadioTarget.checked);
  }

  togglePersonnel() {
    setVisibility(this.doctorTarget, this.doctorRadioTarget.checked);
    setVisibility(this.personnelTarget, this.personnelRadioTarget.checked);
  }
}
