import { Controller } from "stimulus";
import { setVisibility } from "../helpers/stimulus";

export default class extends Controller {
  static targets = ["spinner"];

  connect() {
    setVisibility(this.spinnerTarget, false);
  }

  showSpinner() {
    setVisibility(this.spinnerTarget, true);
  }
}
