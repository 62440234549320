import { Controller } from "stimulus";
import Swal from "sweetalert2";

export default class extends Controller {
  static targets = ["nextUsageDisagreement"];

  toggleDisagreementWarning() {
    if (this.nextUsageDisagreementTarget.checked) {
      Swal.fire({
        title: "Uwaga!",
        text:
          "Posiadasz kolejne niepodpisane umowy. Jeżeli nie wyrazisz tej zgody, nie będziesz w stanie ich podpisać.",
        icon: "warning",
      });
    }
  }
}
