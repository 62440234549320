import { Controller } from "stimulus";
import { setVisibility } from "../helpers/stimulus";

export default class extends Controller {
  static targets = [
    "person",
    "personCheckbox",
    "company",
    "companyCheckbox",
    "companyAddress",
    "companyAddressCheckbox",
  ];

  connect() {
    this.togglePerson();
    this.toggleCompany();
    this.toggleCompanyAddress();
  }

  togglePerson() {
    this._toggle(this.personTarget, this.personCheckboxTarget);
  }

  toggleCompany() {
    this._toggle(this.companyTarget, this.companyCheckboxTarget);
  }

  toggleCompanyAddress() {
    this._toggle(this.companyAddressTarget, this.companyAddressCheckboxTarget, true);
  }

  _toggle(target, checkbox, negative = false) {
    if (negative) {
      setVisibility(target, !checkbox.checked);
    } else {
      setVisibility(target, checkbox.checked);
    }
  }
}
